import {html, css, LitElement} from "lit";
import "./SelectDamagesComponent";
import WeView from "../../component/basecomponent/WeView";


export default class DamagesComponent extends WeView {


    createRenderRoot() {
        return this;
    }

    static properties = {
        orient: { type: String, reflect: true, attribute: false },
        selectedPoints: { type: Set },
        i18n: { type: Object },
    };

    constructor() {
        super();
        this.selectedPoints = new Set();
    }

    _selectedDamages(data) {
        this.selectedPoints = data.detail;
    }


    _resolve() {

        if (this.selectedPoints.size > 0)
            this.dispatchEvent(
                new CustomEvent("damage-resolved", {
                    detail: {
                        selectedPoints: this.selectedPoints,
                    },
                    bubbles: true,
                    composed: true,
                })
            );
    }

    render() {
        return html`
            <style>
                
                .card-box {
                    align-content: center;
                    justify-content: center;
                }
                
                @media screen and (max-width: 460px) {
                    .card-box {
                        display: flex;
                        flex-direction: column;
                        height: 100%;
                        margin-top: 1.5em;
                    
                    }

                    .card-content {
                        margin-bottom: auto;
                    }

                    .car-content {
                        height: 100%
                    }

                    .on-mobile-fullwidth {
                        width: 100%;
                    }
                }

                @media screen and (min-width: 640px) {
                    .container {
                        max-width: 800px;
                    }

                    .car-content {
                        height: 100%;
                        width: 100%;
                    }

                    .card-box {
                        width: 100%;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: auto;
                        padding-top: 3em;
                    }

                    .card-action {
                        margin-top: auto;
                    }

                    .spacer {
                        width: 100%;;
                    }
                }

                .card {
                    height: 100%;
                }
                
                .hero-body {
                    background-color: #f3f4f5;
                    padding: 1.5em;
                }

                .is-we-grey {
                    color: #7c7c7c;
                }

                #svg4 {
                    width: 60%;
                    background-color: white;
                }

                .card-action {
                    margin-top: auto;
                    padding: 1.5em;
                }
                
                .container {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    background-color: white;
                }
                
                select-damages {
                    max-height: 70%;
                }
                
            </style>

            <div class="container">
                <section class="hero is-small">
                    <div class="hero-body">
                            <div class="hero-title">
                                <p class="title is-6">${this.i18n.title}</p>
<!--                                <p class="is-8">${this.i18n.description}</p>-->
                            </div>
                       
                        
                    </div>

                    <svg style="width: 100%"
                         viewBox="0 0 786.45166 71.022777"
                         version="1.1"
                         id="svg4"
                         sodipodi:docname="wave.svg"

                         inkscape:version="1.1.2 (0a00cf5339, 2022-02-04)"
                         xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
                         xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
                         xmlns="http://www.w3.org/2000/svg"
                         xmlns:svg="http://www.w3.org/2000/svg">

                        <path
                                fill="#f3f4f5"
                                fill-opacity="1"
                                d="M 0,25.763344 43.691761,35.773477 C 87.383519,45.890956 174.76705,65.750204 262.15056,70.124601 349.53409,74.33798 436.91761,63.066519 524.30114,50.104337 611.68466,37.303175 699.06818,22.811294 742.75994,15.753211 L 786.45168,8.5877811 V 0 H 742.75994 C 699.06818,0 611.68466,0 524.30114,0 436.91761,0 349.53409,0 262.15056,0 174.76705,0 87.383519,0 43.691761,0 H 0 Z"
                                id="path2"
                                style="stroke:none;stroke-width:0;stroke-miterlimit:4;stroke-dasharray:none"/>
                    </svg>
                </section>
                <div class="card-box">
                    <div class="car-content">

                        <select-damages
                                        .selections=${this.selectedPoints}
                                        @selectd=${this._selectedDamages}
                                        class="component-container">
                        </select-damages>

                    </div>
                    <div class="card-action">
                        <vaadin-button id="nextButton" class="on-mobile-fullwidth" theme="primary large" @click=${this._resolve} aria-label="Next"
                        >${this.translates.btnNext}
                        </vaadin-button
                        >
                    </div>
                </div>
            </div>
        `;
    }
}

window.customElements.define("damage-selector", DamagesComponent);
