/*jshint esversion: 11 */

import DamagesComponent from "./DamagesComponent";
import dateFnsFormat from "date-fns/format";
import dateFnsParse from "date-fns/parse";
const componentName = "damage-selector";

import { elem, localforage, taskId } from "../../config";


const eventAddress = "selectd";
/**
 * Based on WeDAT Plugin POI.
 *
 * @returns {Promise<unknown>}
 * @constructor
 */
export default async function DamageSelector(configuration) {
	window.scrollTo(0, 0);

	return new Promise((resolve, reject) => {
		localforage.getItem(taskId).then((result) => {
			/* This is model from ROOT node */
			const { model, config } = result;

			/* This is component model */
			const { translates, fieldName, component } = configuration;

			window.addEventListener("damage-resolved", (data) => {
				const selectedPoints = Array.from(data.detail.selectedPoints);

				/* Prepare payload to save */
				let newResult = result;


				newResult.model[fieldName] = selectedPoints;
				newResult.model[fieldName] = selectedPoints.join(",");

				/* Save current state */
				localforage.setItem(taskId, newResult).then((r) => resolve());
			},{passive: true});

			let damagesComponent = document.createElement(component);

			/* Restore prev state */
			damagesComponent.translates = translates;
			damagesComponent.i18n = translates;
			damagesComponent.config = config;


			damagesComponent.selectedPoints = new Set(model[fieldName] && model[fieldName].length>0 && model[fieldName]?.split(",")||"");


			elem.innerHTML = "";

			elem.appendChild(damagesComponent);
		});
	});
}
