import {html, css, LitElement} from "lit";

const load = async (url) => {
    try {
        const response = await fetch(url);
        const data = await response.text();
        return data;
    } catch (err) {
        console.error(err);
    }
};

export default class SelectDamagesComponent extends LitElement {
    static styles = css`
		:root {
			background-color: #ffffff;
			position: relative;
		}
        
        .svg-box {
            text-align: center;
        }
        
       
		
	`;

    static properties = {
        device: { type: Object },
        selections: { type: Set },
        ellipses: { type: Array },
        marked: { attribute: false },
        notmarked: { attribute: false },
        orient: { type: String },
    };

    orientation = "";

    constructor() {
        super();

        this.selections = new Set();
        this.ellipses = [];
        this.marked =
            "fill:red;fill-opacity:1;stroke:#727272;stroke-width:0.765;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1";
        this.notmarked =
            "fill:#ffffff;fill-opacity:1;stroke:#727272;stroke-width:0.765;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1";
    }

    firstUpdated(prop) {

        const orientation = window.matchMedia("(orientation: portrait)");

        orientation.addEventListener("change", (e) => this._handleOrientation(e));

        this._handleOrientation(orientation);

        this._init();
    }

    _init() {
        this._drawSVG();

    }

    _initSVG() {
        this._prepareSVG();
        this._restoreListeners();
        this._restoreSVGSelections();
    }

    _handleOrientation(evt) {
        if (evt.matches) {
            this.orient = "portrait";
        } else {
            this.orient = "landscape";
        }

        this.shadowRoot.dispatchEvent(
            new CustomEvent("orientation", {
                detail: this.orient,
                bubbles: true,
                composed: true,
            })
        );

        setTimeout(() => this._init(), 20);
    }

    _restoreListeners() {
        let svg = this.renderRoot.querySelectorAll("ellipse");

        svg.forEach((item) => {
            item.addEventListener("click", (e) => this._selectUnselectItem(e), false);
        });
    }

    _prepareSVG() {
        let svg = this.renderRoot.querySelectorAll("ellipse");

        svg.forEach((item) => {
            if (this.selections.has(item.id))
                item.setAttribute("style", this.notmarked);
            else item.setAttribute("style", this.notmarked);
        });
    }

    _restoreSVGSelections() {
        let svg = this.renderRoot.querySelectorAll("ellipse");

        svg.forEach((item) => {
            if (this.selections.has(item.id)) item.setAttribute("style", this.marked);
        });
    }

    _selectUnselectItem(e) {
        if (!this.selections.has(e.target.id)) {
            this.selections.add(e.target.id);
            e.target.setAttribute("style", this.marked);
        } else {
            this.selections.delete(e.target.id);
            e.target.setAttribute("style", this.notmarked);
        }

        this.shadowRoot.dispatchEvent(
            new CustomEvent("selectd", {
                detail: this.selections,
                bubbles: true,
                composed: true,
            })
        );
    }

    _drawSVG(type) {

        let elem = this.shadowRoot.querySelector("#svgbox");

        let SVG = load("https://wedat-cdn.fra1.cdn.digitaloceanspaces.com/assets/rysunek.svg");
        let SVGR = load("https://wedat-cdn.fra1.cdn.digitaloceanspaces.com/assets/rysunek90.svg");


        elem.innerHTML = "";

        if (this.orient === "portrait")
            SVGR.then(data => {
                elem.innerHTML = data
                this._initSVG()
            });
        else
            SVG.then(data => {
                elem.innerHTML = data
                this._initSVG()
            });


    }

    render() {
        return html`
            <div class="svg-box" id="svgbox"></div> `;
    }
}

window.customElements.define("select-damages", SelectDamagesComponent);
